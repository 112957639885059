import React from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import PageContent from './pagetemplates/pagecontent';
import Layout from './components/pagestructure/layout';
import { AnimatePresence } from 'framer-motion';
import SecurePage from './pagetemplates/securepage';
import Betting from './pagetemplates/betting';
import NewbridgePredictions from './pagetemplates/newbridgepredictions';
import Running from './pagetemplates/running';
import Savings from './pagetemplates/savings';
import NflZone from './pagetemplates/nflzone';
import NflAdmin from './pagetemplates/nfladmin';
import Football from "./pagetemplates/football";
import FootballResults from "./pagetemplates/footballResults";

function App() {
  const location = useLocation();

  return (
    <AnimatePresence mode='wait' initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />}>
          <Route index element={<PageContent />} />
          <Route path="secure-page" element={<SecurePage />} />
          <Route path="/projects/accumulators" element={<Betting />} />
          <Route path="/projects/newbridge-predictions" element={<NewbridgePredictions/>} />
          <Route path="/projects/parkruns" element={<Running activity="Parkrun"/>} />
          <Route path="/projects/savings" element={<Savings/>} />
          <Route path="/projects/nfl-zone" element={<NflZone/>} />
          <Route path="/projects/nfl-admin" element={<NflAdmin/>} />
          <Route path="/projects/football-zone" element={<Football/>} />
          <Route path="/projects/football-results" element={<FootballResults/>} />
          <Route path="*" element={<PageContent/>} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default App;