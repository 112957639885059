import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/nfl.css';

function Gameweeks() {
    const [schedule, setSchedule] = useState(null);
    const [gameweek, setGameWeek] = useState(1);
    const [fixtures, setFixtures] = useState(null);
    const [predictionsPercentage, setPredictionsPercentage] = useState(0);
    const [picksPercentage, setPicksPercentage] = useState(0);

    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/nflgameweeksdata';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            setSchedule(data.gameweekFixtures);
            setFixtures(data.gameweekFixtures[gameweek]);
            setPredictionsPercentage(data.predictionsPercentage);
            setPicksPercentage(data.picksPercentage);
        });
    }, []);

    function selectedGameweek(event, value) {
        event.preventDefault();
        setGameWeek(value.gameweekNo);
        setFixtures(schedule[value.gameweekNo]);
    }

    return (      
        <table className="gameweeks">
            <thead>
                <tr>
                {schedule && Object.keys(schedule).slice(0, 9).map((gameweekNo) => (     
                    <React.Fragment key={gameweekNo}>
                        <th className={`"gameweek" ${gameweek==gameweekNo ? "active" : ""}`}><button onClick={(e) => selectedGameweek(e, {gameweekNo})}>{gameweekNo}</button></th>                        
                    </React.Fragment>           
                ))}
                <th className="accuracy" colSpan="4">Predicted Results Accuracy:</th>
                <th className="accuracyPercentage" colSpan="2">{predictionsPercentage}%</th>
                </tr>
                <tr>
                {schedule && Object.keys(schedule).slice(9, 18).map((gameweekNo) => (     
                    <React.Fragment key={gameweekNo}>
                        <th className={`"gameweek" ${gameweek==gameweekNo ? "active" : ""}`}><button onClick={(e) => selectedGameweek(e, {gameweekNo})}>{gameweekNo}</button></th>                        
                    </React.Fragment>           
                ))}                
                <th className="accuracy" colSpan="4">My Picks Accuracy:</th>
                <th className="accuracyPercentage" colSpan="2">{picksPercentage}%</th>
                </tr>
            </thead>
            <tbody>
            {fixtures && fixtures.map((fixture, index) => (                
                <tr key={index}>
                    <td colSpan="1" className="teamImageRow"><img src={`/images/nfl/${fixture.homeTeam.replace(/\s/g, "").toLowerCase()}.svg`} alt={fixture.homeTeam} width="35em" /></td>
                    <td colSpan="3">{fixture.homeTeam}</td>
                    <td colSpan="1" className="score"><span>{fixture.homeScore}</span></td>
                    <td colSpan="1" className="versus">Vs</td>
                    <td colSpan="1" className="score"><span>{fixture.awayScore}</span></td>
                    <td colSpan="3">{fixture.awayTeam}</td>
                    <td colSpan="1" className="teamImageRow"><img src={`/images/nfl/${fixture.awayTeam.replace(/\s/g, "").toLowerCase()}.svg`} alt={fixture.awayTeam} width="35em" /></td>
                    <td colSpan="2"></td>
                    <td colSpan="1" className="predictionLeft">Prediction:</td>
                    <td colSpan="1" className="teamImageRow predictionRight"><img src={`/images/nfl/${fixture.prediction === "Home" ? fixture.homeTeam.replace(/\s/g, "").toLowerCase() : fixture.awayTeam.replace(/\s/g, "").toLowerCase()}.svg`} alt={fixture.awayTeam} width="35em" /></td>
                </tr>
                ))}
            </tbody>
        </table>        
    );
}

export default Gameweeks;