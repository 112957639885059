import React from 'react';
import Accumulators from '../components/projects/accumulators/accumulators'

function Betting() {
    return (
        <div className="page">
            <Accumulators />
        </div>
    );
}

export default Betting;