import React from 'react';
import SavingsGraph from '../components/projects/savingsinterest/savingsGraph'
import SavingsAdmin from '../components/projects/savingsinterest/savingsAdmin'

function Savings() {
    return (
        <div className="page">
            <SavingsGraph />
            <SavingsAdmin />
        </div>
    );
}

export default Savings;