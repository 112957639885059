import React, { useState, useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import axios from 'axios';
import '../../../css/tablestandings.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const EnglishLeagueResults = () => {
    const [selectedCompetition, setSelectedCompetition] = useState();
    const [fixtures, setFixtures] = useState();
    const [selectedFixtureDate, setSelectedFixtureDate] = useState();
    const [teams, setTeams] = useState([]);
    const [results, setResults] = useState([]);
    const [postStatus, setPostStatus] = useState([]);

    useEffect(() => {
        setSelectedFixtureDate(new Date());
    }, []);

    function selectedCompetitionChanged(e) {
        setSelectedCompetition(e.target.value);
        const apiUrl = 'https://api.davidbuckell.com/api/footballresultsdata?competition=' + e.target.value;
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            if (data && data.fixtures) {
                setFixtures(data.fixtures);
                // obtain unique teams
                const teamsArray = [];
                data.fixtures.map((fixture) => {
                    var existingTeam = teamsArray.find((x) => x === fixture.homeTeam);
                    if (!existingTeam) {
                        teamsArray.push(fixture.homeTeam);
                    }
                });
                setTeams(teamsArray);
                setResults([]);
            }
        });
    }

    function addResult() {
        var newResult = { homeTeam: `${teams[0]}`, homeGoals: 0, awayGoals: 0, awayTeam: `${teams[0]}` };
        setResults(results => [...results, newResult]);
    }

    function removeResult(e, index) {
        const currentResults = [...results];
        currentResults.splice(index, 1);
        setResults(currentResults);
    }

    function homeTeamChanged(e, index) {
        const resultsArray = [...results];
        resultsArray[index].homeTeam = e.target.value;
        setResults(resultsArray);
    }

    function homeGoalsChanged(e, index) {
        const resultsArray = [...results];
        resultsArray[index].homeGoals = parseInt(e.target.value);
        setResults(resultsArray);
    }

    function awayTeamChanged(e, index) {
        const resultsArray = [...results];
        resultsArray[index].awayTeam = e.target.value;
        setResults(resultsArray);
    }

    function awayGoalsChanged(e, index) {
        const resultsArray = [...results];
        resultsArray[index].awayGoals = parseInt(e.target.value);
        setResults(resultsArray);
    }

    function setFixtureDate(e) {
        setSelectedFixtureDate(e);
    }

    function clearForm() {
        setFixtures({});
        setResults([]);
        document.getElementById('competition').selectedIndex = 0;
        setPostStatus('');
    }

    function submitResults(e) {
        e.preventDefault();

        const tempFixtureDate = new Date(selectedFixtureDate);
        tempFixtureDate.setTime(tempFixtureDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
        const fixtureDate = tempFixtureDate.toISOString();

        const updatedFixtures = [...fixtures];
        results.forEach(result => {
            const fixture = updatedFixtures.find(x => x.homeTeam === result.homeTeam && x.awayTeam === result.awayTeam);
            fixture.homeGoals = result.homeGoals;
            fixture.awayGoals = result.awayGoals;
            fixture.fixtureDate = fixtureDate;
            fixture.result = result.homeGoals === result.awayGoals ? 'Draw' : result.homeGoals > result.awayGoals ? 'Home' : 'Away';
        });

        setFixtures(updatedFixtures);

        const data = {
            "Competition": selectedCompetition,
            "Fixtures": fixtures
        };

        const apiUrl = 'https://api.davidbuckell.com/api/footballresultsdata';
        // When posting data, any strings containing null values must support nullable in .NET core app, either by string? or allowing nullable reference types ('enable' didn't work without string? though)
        axios.post(apiUrl, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(() => {
            setPostStatus('Successfully posted results!');
            setTimeout(() => clearForm(), 1000);
        }).catch((err) => setPostStatus(`Failed to post results - ${err}`));
    }

    return (
        <React.Fragment>
            <article id='topscorers'>

                <div className='row'>
                    <div className='col-3'>
                        <hr />
                        <select id='competition' onChange={selectedCompetitionChanged}>
                            <option value='Default'>Select competition...</option>
                            <option value='EnglishPremierLeagueFixtures'>English Premier League</option>
                            <option value='EnglishChampionshipFixtures'>English Championship</option>
                            <option value='EnglishLeagueOneFixtures'>English League One</option>
                            <option value='EnglishLeagueTwoFixtures'>English League Two</option>
                            <option value='EnglishNationalLeagueFixtures'>English National League</option>
                            <option value='ScottishPremiershipFixtures'>Scottish Premiership</option>
                            <option value='ScottishChampionshipFixtures'>Scottish Championship</option>
                            <option value='ScottishLeagueOneFixtures'>Scottish League One</option>
                            <option value='ScottishLeagueTwoFixtures'>Scottish League Two</option>
                        </select>
                    </div>
                    <div className='col-5'>
                        <hr />
                        <Calendar onChange={setFixtureDate} />
                    </div>
                    <div className='col-2'>
                        <hr />
                        <button onClick={addResult}>Add Result</button>
                    </div>
                    <div className='col-2'>
                        <hr />

                        <AuthenticatedTemplate>
                            <input type='submit' value='Submit' className='primary' onClick={submitResults} />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <input type='submit' value='Submit' className='primary disabled'/>
                        </UnauthenticatedTemplate>
                    </div>
                </div>

                <form onSubmit={submitResults}>
                    {results && results.map((result, resultIndex) => (
                        <React.Fragment key={`fragment-${resultIndex}`}>
                            <hr className='col-12' key={`hr-${resultIndex}`} />
                            <div className='row' key={`parentdiv-${resultIndex}`}>
                                <select id='homeTeam' key={`hometeam-${resultIndex}`} className='col-4' onChange={(e) => homeTeamChanged(e, resultIndex)}>
                                    {teams.map((team, teamIndex) => (
                                        <option key={`home-${resultIndex}-${teamIndex}`} value={team}>{team}</option>
                                    ))}
                                </select>

                                <div className='col-1' key={`homegoalsdiv-${resultIndex}`}>
                                    <input id='homeGoals' key={`homegoals-${resultIndex}`} pattern='[0-9]*' style={{ width: '50px' }} className='goals' type='number' min={0} name={`homegoals-${resultIndex}`} defaultValue={result.homeGoals} onBlur={(e) => homeGoalsChanged(e, resultIndex)} />
                                </div>
                                <div className='col-1' key={`awaygoalsdiv-${resultIndex}`}>
                                    <input id='awayGoals' key={`awaygoals-${resultIndex}`} style={{ width: '50px' }} className='goals' type='number' min={0} name={`awaygoals-${resultIndex}`} defaultValue={result.awayGoals} onBlur={(e) => awayGoalsChanged(e, resultIndex)} />
                                </div>

                                <select id='awayTeam' key={`awayteam-${resultIndex}`} className='col-4' onChange={(e) => awayTeamChanged(e, resultIndex)}>
                                    {teams.map((team, teamIndex) => (
                                        <option key={`away-${resultIndex}-${teamIndex}`} value={team}>{team}</option>
                                    ))}
                                </select>

                                <div className='col-2' key={`remove-${resultIndex}`}>
                                    <button onClick={(e) => removeResult(e, resultIndex)}>X</button>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </form>

                <hr />
                <h5>{postStatus}</h5>
                <span>Results</span>
                <textarea id='resultsOutput' rows={10} cols={20} value={JSON.stringify(results)} readOnly></textarea>

                <hr />
                <span>Fixtures</span>
                <textarea id='fixturesOutput' rows={10} cols={20} value={JSON.stringify(fixtures)} readOnly></textarea>

            </article>
        </React.Fragment>
    );
}

export default EnglishLeagueResults;