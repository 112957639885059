import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/nfl.css';

function GameweeksAdmin() {
    const [schedule, setSchedule] = useState(null);
    const [gameweek, setGameWeek] = useState(1);
    const [fixtures, setFixtures] = useState(null);

    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/nflgameweeksdata';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            setSchedule(data.gameweekFixtures);
            setFixtures(data.gameweekFixtures[gameweek]);
        });
    }, []);

    function selectedGameweek(event, value) {
        event.preventDefault();
        setGameWeek(value.gameweekNo);
        setFixtures(schedule[value.gameweekNo]);
    }

    function selectedPick(event, index) {
        event.preventDefault();
        var pick = event.target.value;
        const gameweekFixtures = [...fixtures];
        gameweekFixtures[index]["pick"] = pick;
        setFixtures(gameweekFixtures);
    }

    function scoreChanged(e, index, venue) {
        const gameweekFixtures = [...fixtures];        
        if (venue == 'home') {
            gameweekFixtures[index]["homeScore"] = Number(e.target.value);
        }
        else {
            gameweekFixtures[index]["awayScore"] = Number(e.target.value);
        }

        setFixtures(gameweekFixtures);
    }

    function postResults(e) {
        e.preventDefault();
        
        const headers = {
            'content-type': 'application/json'
        };
        const data = {
            "GameweeksBlob": {"gameweekFixtures": schedule}
        };
        console.log('about to post:' + JSON.stringify(data));
        const apiUrl = 'https://api.davidbuckell.com/api/nflgameweeksdata';
        axios.post(apiUrl, data, { headers });
    }

    return (
        <form onSubmit={postResults}>
            <div className="row submitDataRow">
                <button type="submit" className="col-12 submitData">Submit Results</button>
            </div>
            <table className="gameweeks">
                <thead>
                    <tr>
                        <th colSpan="12">
                            {schedule && Object.keys(schedule).map((gameweekNo) => (
                                <React.Fragment key={gameweekNo}>
                                    <button className={`"gameweek" ${gameweek==gameweekNo ? "active" : ""}`} onClick={(e) => selectedGameweek(e, { gameweekNo })}>{gameweekNo}</button>
                                </React.Fragment>
                            ))}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {fixtures && fixtures.map((fixture, index) => (
                        <tr key={index}>
                            <td colSpan="1" className="teamImageRow"><img src={`/images/nfl/${fixture.homeTeam.replace(/\s/g, "").toLowerCase()}.svg`} alt={fixture.homeTeam} width="35em" /></td>
                            <td colSpan="1">{fixture.homeTeam}</td>
                            <td colSpan="2" className="score"><input type="text" value={fixture.homeScore} onChange={(e) => scoreChanged(e, index, 'home')}></input></td>
                            <td colSpan="1" className="versus">Vs</td>
                            <td colSpan="2" className="score"><input type="text" value={fixture.awayScore} onChange={(e) => scoreChanged(e, index, 'away')}></input></td>
                            <td colSpan="1">{fixture.awayTeam}</td>
                            <td colSpan="1" className="teamImageRow"><img src={`/images/nfl/${fixture.awayTeam.replace(/\s/g, "").toLowerCase()}.svg`} alt={fixture.awayTeam} width="35em" /></td>
                            <td colSpan="1">Pick:</td>
                            <td colSpan="4"><select onChange={(e) => selectedPick(e, index)} value={fixture.pick}><option value="Home">Home</option><option value="Away">Away</option></select></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </form>
    );
}

export default GameweeksAdmin;