import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function Content(props) {
    var content = props.pageContent;    
    
    return (
        <section>
            <header className="main">
                <h1>{content.Headline}</h1>
            </header>

            <span className="image main"><img src={content.BannerImage} alt={content.ImageAlt} /></span>

            <ReactMarkdown children={content.Content} rehypePlugins={[rehypeRaw]}/>
                
        </section>
    );
}

export default Content;