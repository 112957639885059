import React from 'react';
import { Link } from 'react-router-dom';

function NavigationHubLinks(props) {
    const navHubLinks = props.navHubLinks;
    return (
        <section>            
            <header className="major">
                <h2>Featured</h2>
            </header>
            <div className="mini-posts">
                {navHubLinks && navHubLinks.map((link, index) => (
                  <article key={index}>                    
                    <Link className='image' to={link.LinkUrl}><img src={link.LinkImage} alt={link.LinkImageAlt} /></Link>
                    <p>{link.LinkTitle}</p>
                  </article>
                ))}
            </div>
            <ul className="actions">
                <li><Link className='button' to="/">More</Link></li>
            </ul>
        </section>
    );
}

export default NavigationHubLinks;