import React from 'react';
import { Link } from 'react-router-dom';

function HubLinks(props) {
    var content = props.pageContent;

    return (
        <section>
            <header className="major">
                <h2>{content.HubLinksTitle}</h2>
            </header>
            <div className="posts">
                {content && content.HubLinks && content.HubLinks.map((link, index) => (
                    <article key={index}>                        
                        <Link className='image' to={link.LinkUrl}><img src={link.LinkImage} alt={link.LinkImageAlt} /></Link>
                        <h3>{link.LinkTitle}</h3>
                        <p>{link.LinkSummary}</p>
                        <ul className="actions">
                            <li><Link className='button' to={link.LinkUrl}>More</Link></li>
                        </ul>
                    </article>
                ))}
            </div>
        </section>
    );
}

export default HubLinks;