import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Predictions from '../components/projects/nfl/predictions'
import Standings from '../components/projects/nfl/standings'
import Gameweeks from '../components/projects/nfl/gameweeks'
import Stats from '../components/projects/nfl/stats'
import '../css/tabs.css'

function NflZone() {
    function hideHenry() {
        $('.nflPlayer').hide();
    }

    return (
        <div className="page">
            <article id="nfl">
                <div id="parent">
                    <div id="container">
                        <h3 className="nflTitle">NFL Zone</h3>
                        <img src={"/images/nfl/derrickhenry.gif"} className="nflPlayer" alt="derrickhenry" onAnimationEnd={hideHenry} />
                    </div>
                </div>
                <div>
                    <Tabs>
                        <TabList>
                            <Tab>Predictions</Tab>
                            <Tab>Standings</Tab>
                            <Tab>Schedule</Tab>
                            <Tab>Stats</Tab>
                        </TabList>
                        <TabPanel>
                            <Predictions />
                        </TabPanel>
                        <TabPanel>
                            <Standings />
                        </TabPanel>
                        <TabPanel>
                            <Gameweeks />
                        </TabPanel>
                        <TabPanel>
                            <Stats />
                        </TabPanel>
                    </Tabs>
                </div>
            </article>
        </div>
    );
}

export default NflZone;