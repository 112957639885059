import React, { useEffect, useState } from "react";
import axios from 'axios';
import Text from 'react-svg-text';
import Moment from 'react-moment';
import '../../../css/predictions.css';

const Predictions = () => {
    const [playerTotalPoints, setPlayerTotalPoints] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [multiplier, setMultiplier] = useState(0);
    const [lastUpdated, setLastUpdated] = useState("");
    const [firstPlaceWinnings, setFirstPlaceWinnings] = useState("");
    const [secondPlaceWinnings, setSecondPlaceWinnings] = useState("");
    const [thirdPlaceWinnings, setThirdPlaceWinnings] = useState("");
    const [showPoints, setShowPoints] = useState(true);
    var bar_w = 50;

    var winningsStyle = {
        margin: '1em 0 2em 0',
        width: '25%',
        lineHeight: '0.1em'
    }

    let ukPounds = Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: 2
    });

    function showTablePoints() {
        setShowPoints(true);
    }

    function showTablePositions() {
        setShowPoints(false);
    }

    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/predictionsdata';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            //data.PlayerTotalPoints.forEach(function(part, index, theArray) {
            //theArray[index].Value = theArray[index].Value * 2;
            //});
            var maxPoints = Math.max(...data.PlayerTotalPoints.map(item => item.value));
            setMultiplier(200 / maxPoints);
            setPlayerTotalPoints(data.PlayerTotalPoints);
            setTableRows(data.TableRows);
            setLastUpdated(data.LastUpdated);
            setFirstPlaceWinnings(data.FirstPlaceWinnings);
            setSecondPlaceWinnings(data.SecondPlaceWinnings);
            setThirdPlaceWinnings(data.ThirdPlaceWinnings);
        });
    }, []);

    return (
        <article id="predictions">
            <h3>Points Total</h3><br />

            <svg id="pointsTotals" className="predictions">
                {playerTotalPoints && playerTotalPoints.map((item, index) => (
                    <React.Fragment key={`playerpts${index}`}>
                        <Text x={bar_w / 4 + index * 60} y={220 - item.value * multiplier} width={bar_w} opacity="1" className="predictions">
                            {(item.value) + 'pts'}
                        </Text>
                        <rect x={5 + index * 60} y="0" width={bar_w} height="0" className="predictions">
                            <animate
                                attributeName="height"
                                from="0"
                                to={item.value * multiplier}
                                dur="1s"
                                fill="freeze"
                            />
                            <animate
                                attributeName="y"
                                from={236 + (item.value * multiplier)}
                                to={236 - (item.value * multiplier)}
                                dur="1s"
                                fill="freeze"
                            />
                        </rect>
                        <image xlinkHref={"/images/predictions/" + item.key.toLowerCase() + ".png"} x={5 + index * 60} y="236" width={bar_w} height="69" />
                    </React.Fragment>
                ))}
            </svg>

            
            <table style={winningsStyle}>
                <thead>
                    <tr>
                        <th>Place</th>
                        <th>Winnings</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1st</td>
                        <td>{ukPounds.format(firstPlaceWinnings)}</td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td>{ukPounds.format(secondPlaceWinnings)}</td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td>{ukPounds.format(thirdPlaceWinnings)}</td>
                    </tr>
                </tbody>
            </table>													
            <br /><br />
            <div id="table">
                <h3>Live league table</h3>
                <div className="row">
                    <div className="col-6 col-12-small">
                        <input type="radio" id="predictions-points" name="predictionsRadio" value={true} checked={showPoints} onChange={showTablePoints} />
                        <label htmlFor="predictions-points">Points</label>
                    </div>
                    <div className="col-6 col-12-small">
                        <input type="radio" id="predictions-positions" name="predictionsRadio" value={false} checked={!showPoints} onChange={showTablePositions} />
                        <label htmlFor="predictions-positions">Positions</label>
                    </div>
                </div>
                <table className="predictions">
                    <thead>
                        <tr>
                            <th className="center">Pos</th>
                            <th>Team</th>
                            {playerTotalPoints && playerTotalPoints.map((item, index) => (
                                <th key={`th${index}`} className="center">
                                    <img key={item + 4} src={"/images/predictions/" + item.key.toLowerCase() + ".png"} className="player" alt={item.key} />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows && tableRows.map((item, index) => (
                            <tr key={`tr${index}`}>
                                <td align="center">{item.Position}</td>
                                <td className="team">{item.Team}</td>
                                {item.PlayerPoints && item.PlayerPoints.map((pts, index) => (
                                    <React.Fragment key={`td${index}`}>
                                        { showPoints
                                            ? <td className="points" style={{ backgroundColor: pts.value === 10 ? "darkgreen" : "" }}>{pts.value}</td>
                                            : <td className="positions" style={{ backgroundColor: pts.value === 10 ? "darkgreen" : "" }}>{pts.key}</td>
                                        }
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="lastUpdated">
                <span>Last Updated: </span>                
                <span><Moment>{lastUpdated}</Moment></span>
            </div>
        </article>
    );
}

export default Predictions;