import React from 'react';
import FootballTables from '../components/projects/football/footballtables';

function Football() {
    return (
        <div className="page">
            <FootballTables />
        </div>
    );
}

export default Football;