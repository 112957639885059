import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/nfl.css';

function StatsAdmin() {
    const [stats, setStats] = useState(null);
    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/nflstatsdata';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            setStats(data.stats);
            console.log(data.stats);
        });

    }, []);

    function yardsChanged(teamNickname, value, statType) {        
        const allStats = stats;
        const teamStats = allStats[teamNickname];        
        teamStats[statType] = Number(value);

        setStats(allStats);
    }

    function postStats(e) {
        e.preventDefault();

        const headers = {
            'content-type': 'application/json'
        };
        const data = {
            stats
        };
        console.log('about to post:' + JSON.stringify(data));
        const apiUrl = 'https://api.davidbuckell.com/api/nflstatsdata';
        axios.post(apiUrl, data, { headers });
    }

    /* used OnBlur here so that state is only updated when value changed, rather than every keypress.
        within gameweeks this approach updated the same index of every gameweek so had to use onChange instead */

    return (
        <form onSubmit={postStats}>
            <div className="row submitDataRow">
                <button type="submit" className="col-12 submitData">Submit Stats</button>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="table-wrapper">
                        <table className={`nfl`}>
                            <thead>
                                <tr>
                                    <th>Team</th>
                                    <th>Offense Passing Yards</th>
                                    <th>Offense Rushing Yards</th>
                                    <th>Defense Passing Yards</th>
                                    <th>Defense Rushing Yards</th>
                                    <th>Power Ranking</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stats && Object.entries(stats).map(([teamNickname, stats]) => (
                                    <tr key={`${teamNickname}`}>                                        
                                        <td>
                                            <div className="teamImageRow">
                                                <img src={`/images/nfl/${stats.teamName.replace(/\s/g, "").toLowerCase()}.svg`} alt={teamNickname} width="35em" />
                                                <span className="teamname">{teamNickname}</span>
                                            </div>
                                        </td>
                                        <td><input type="text" defaultValue={stats.offensePassingYards} onBlur={(e) => yardsChanged(teamNickname, e.target.value, "offensePassingYards")}></input></td>
                                        <td><input type="text" defaultValue={stats.offenseRushingYards} onBlur={(e) => yardsChanged(teamNickname, e.target.value, "offenseRushingYards")}></input></td>
                                        <td><input type="text" defaultValue={stats.defensePassingYards} onBlur={(e) => yardsChanged(teamNickname, e.target.value, "defensePassingYards")}></input></td>
                                        <td><input type="text" defaultValue={stats.defenseRushingYards} onBlur={(e) => yardsChanged(teamNickname, e.target.value, "defenseRushingYards")}></input></td>
                                        <td><input type="text" defaultValue={stats.powerRanking} onBlur={(e) => yardsChanged(teamNickname, e.target.value, "powerRanking")}></input></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>                
            </div>
        </form>
    );
}

export default StatsAdmin;