import React from "react";
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <section>
            <div className="content">
                <h2>Oops we can't find what you're looking for here!</h2>
                <p>Maybe start again from <Link to="/">here</Link>?</p>
            </div>
            <span className="image object"><img src="/images/notfound.jpg" alt="" /></span>
        </section>
    );
}

export default NotFound;