import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GameweeksAdmin from '../components/projects/nfl/gameweeksadmin'
import StatsAdmin from '../components/projects/nfl/statsadmin'

function NflAdmin() {
    function hideHenry() {
        $('.nflPlayer').hide();
    }

    return (
        <div className="page">
            <article id="nfl">
                <div id="parent">
                    <div id="container">
                        <h3 className="nflTitle">NFL Admin</h3>
                        <img src={"/images/nfl/derrickhenry.gif"} className="nflPlayer" alt="derrickhenry" onAnimationEnd={hideHenry} />
                    </div>
                </div>
                <div>
                    <Tabs>
                        <TabList>
                            <Tab>Schedule</Tab>
                            <Tab>Stats</Tab>
                        </TabList>
                        <TabPanel>
                            <GameweeksAdmin />
                        </TabPanel>
                        <TabPanel>
                            <StatsAdmin />
                        </TabPanel>
                    </Tabs>
                </div>
            </article>
        </div>
    );
}

export default NflAdmin;