import React, { useEffect, useState } from "react";
import axios from 'axios';

const SavingsGraph = () => {
    const [breakdown, setBreakdown] = useState([]);

    let ukPounds = Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: 2
    });

    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/savingsInterestBreakdownData';
        axios.get(apiUrl).then((result) => {
            var data = result.data;            
            var mappedData = data.savingsInterestBreakdown.map((row, index) => 
              ({
                date: `${new Date(row.date).toLocaleDateString()}`,
                amount: row.savingsInterestAmount
              }));
            setBreakdown(mappedData);
        });
    }, []);

    return (
        <React.Fragment>
            <h3>Interest Savings</h3><br />

            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                {breakdown && breakdown.map((row, index) => (
                    <tr key={index}>
                        <td>{row.date}</td>
                        <td>{ukPounds.format(row.amount)}</td>
                    </tr>
                ))}
                </tbody>
            </table>            

        </React.Fragment>
    );
}

export default SavingsGraph;