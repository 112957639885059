import React from 'react';
import { Link } from 'react-router-dom';

function PrimaryLinks(props) {
    var content = props.pageContent;
    return (
        <section>
            <header className="major">
                <h2>{content && content.PrimaryLinksTitle}</h2>
            </header>
            <div className="features">
                {content && content.PrimaryLinks && content.PrimaryLinks.map((link, index) => (
                    <article key={index}>
                        <span className={link.LinkIconClass}></span>
                        <div className="content">
                            <h3><Link className='image' to={link.LinkUrl}>{link.LinkTitle}</Link></h3>
                            <p>{link.LinkSummary}</p>
                        </div>
                    </article>
                ))}
            </div>
        </section>
    );
}

export default PrimaryLinks;