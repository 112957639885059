import React from 'react';
import Parkrun from '../components/projects/running/parkrun'

function Running(props) {
    var activity = props.activity;
    return (
        <div className="page">
            {activity == "Parkrun" &&
                <Parkrun />
            }            
        </div>
    );
}

export default Running;