export const msalConfig = {
    auth: {
        clientId: "d73ab6c1-4d71-4ae9-a651-bd361e187237",
        authority: "https://login.microsoftonline.com/6fb68315-3b88-40d8-bd72-41d9bf6b7895",
        redirectUri: "https://www.davidbuckell.com/",
        postLogoutRedirectUri: "https://www.davidbuckell.com/"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

// app settings
// https://learn.microsoft.com/en-us/javascript/api/overview/azure/app-configuration-readme?view=azure-node-latest