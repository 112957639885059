import React, { useState, useRef } from "react";
import Chevron from "./chevron";
import '../../../src/css/accordion.css';

function AccasAccordion(props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    let ukPoundsWithoutPence = Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: 0
    });

    let ukPounds = Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: 2
    });

    var bet = props.bet;
    var displayAll = props.displayAll;
    var isTopScorerBet = bet.selections.Football && Object.entries(bet.selections.Football)[0][1].outcome === "Top Scorer";
    var hasFolds = bet.minFolds !== bet.maxFolds || isTopScorerBet;
    var isWinning = bet.winningFolds + bet.wonFolds >= bet.minFolds;
    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
        );
    }    
    
    return (        
        <div className="accordion__section">
            <button className={`accordion ${setActive} panel-header panel-toggle ${isWinning ? 'winning' : ''}`} onClick={toggleAccordion}>
                <span>                    
                    {hasFolds ?
                    <React.Fragment>
                        <span>{`${bet.betTitle}`} staking </span>
                        {ukPoundsWithoutPence.format(bet.stake)}
                        <span> returning </span>
                        {ukPounds.format(bet.currentPayout)}
                        <span> of </span>
                        {ukPounds.format(bet.maxPayout)}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <span>{`${bet.betTitle}`} staking </span>                        
                        {ukPoundsWithoutPence.format(bet.stake)}
                        <span> returning </span>                        
                        {ukPounds.format(bet.maxPayout)}
                    </React.Fragment>
                    }                        
                    <span>&nbsp;&nbsp;<img src="/images/betting/losing.png" width="1.5%" alt="losing" />{bet.losingFolds}</span>
                    <span>&nbsp;&nbsp;<img src="/images/betting/winning.png" width="1.5%" alt="winning" />{bet.winningFolds}</span>
                    <span>&nbsp;&nbsp;<img src="/images/betting/won.png" width="1.5%" alt="won" />{bet.wonFolds}</span>
                    <span>&nbsp;&nbsp;<img src="/images/betting/pending.png" width="1.5%" alt="pending" />{bet.pendingFolds}</span>
                </span>
                <Chevron className={displayAll ? 'accordion__icon rotate' : `${setRotate}`} width="5px" height="5px" fill={"whitesmoke"} />
            </button>
            <div
                ref={content}
                style={{ maxHeight: displayAll ? `${content.current.scrollHeight}px` : `${setHeight}` }}
                className="accordion__content"
            >
                <div className="accordion__text selections">
                    {Object.entries(bet.selections).map(([sport, value]) => (
                        <React.Fragment key={sport}>
                            <div className="panelDiv"><span className="panelSpan">{sport}</span></div>
                            <div>
                                {value && value.map((selection, index) => (
                                    <div key={index}>{selection.competition} {selection.outcome}: {selection.selection} &nbsp;<img src={"/images/betting/" + selection.status.toLowerCase() + ".png"} width="1.5%" alt={selection.status} /></div>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AccasAccordion;