import React from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
    return (
        <header id="header">            
            <NavLink to="/" className="logo"><strong>David Buckell</strong>.com</NavLink>
            <ul className="icons">
                <li><a href="https://github.com/davidbuckell" target="_blank" rel="noopener noreferrer" className="icon brands fa-github"><span className="label">GitHub</span></a></li>
                <li><a href="https://twitter.com/DavidBuckell" target="_blank" rel="noopener noreferrer" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="https://www.facebook.com/davidbuckell1978" target="_blank" rel="noopener noreferrer" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.linkedin.com/in/david-buckell/" target="_blank" rel="noopener noreferrer" className="icon brands fa-linkedin"><span className="label">LinkedIn</span></a></li>
                <li><a href="https://www.youtube.com/c/DavidBuckell" target="_blank" rel="noopener noreferrer" className="icon brands fa-youtube"><span className="label">YouTube</span></a></li>
            </ul>
        </header>
    );
}

export default Header;