import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useIsAuthenticated } from "@azure/msal-react";
import NavigationHubLinks from "../navigation/navigationhublinks";
import NavigationLinks from "../navigation/navigationlinks";
import Search from "../navigation/search";
import Footer from "./footer";
import { SignInButton } from "../authentication/signInButton";
import { SignOutButton } from "../authentication/signOutButton";

function SideNavigation() {
    const isAuthenticated = useIsAuthenticated();
    const pages = [
        { id: 0, title: 'home', url: '/' },
        { id: 1, title: 'hub', url: 'hub' },
        { id: 2, title: 'content', url: 'content' },
        { id: 3, title: 'secure page', url: 'secure-page', isSecure: true }
    ];

    const [navLinks, setNavLinks] = useState(null);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const [searchLinks, setSearchLinks] = useState([]);
    const [navHubLinks, setNavHubLinks] = useState([]);
    var searchArray = [];
    var searchIndex = 0;

    function convertToSearchItem(link) {
        searchArray.push({ id: searchIndex++, title: link.title, url: link.url});
        link.childLinks && link.childLinks.map((link) => convertToSearchItem(link))
    }

    useEffect(() => {
        window.executeMainJs();
        document.body.classList.remove('is-preload');
        const navigationApiUrl = 'https://cms.davidbuckell.com/umbraco/api/navigation/getnavigation';
        axios.get(navigationApiUrl).then((result) => {
            var data = result.data;
            setNavLinks(data);
            setSelectedLinks(window.location.pathname.split('/'));
            data.map((link, index) => (
                convertToSearchItem(link, index)
            ));            
            setSearchLinks(searchArray);
        });

        const homePageUrl = 'https://cms.davidbuckell.com';
        axios.get(homePageUrl).then((result) => {
            var data = result.data.NavigationHubLinks;
            setNavHubLinks(data);
        });
    }, []);

    return (
        <div id="sidebar">
            <React.Fragment>
                <div className="inner">
                    <Search searchLinks={searchLinks} />
                    { isAuthenticated ? <SignOutButton/> : <SignInButton/> }
                    <NavigationLinks navLinks={navLinks} setNavLinks={setNavLinks} selectedLinks={selectedLinks} setSelectedLinks={setSelectedLinks} />
                    <NavigationHubLinks navHubLinks={navHubLinks} />
                    <Footer />
                </div>
            </React.Fragment>
        </div>
    );
}

export default SideNavigation;