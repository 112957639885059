import React from 'react';
import Predictions from '../components/projects/newbridgepredictions/predictions'

function NewbridgePredictions() {
    return (
        <div className="page">
            <Predictions />
        </div>
    );
}

export default NewbridgePredictions;