import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomePageIntro from '../components/pagestructure/homepageintro';
import PrimaryLinks from '../components/navigation/primarylinks';
import HubLinks from '../components/navigation/hublinks';
//import ProjectHubLinks from '../components/navigation/projecthublinks';
import Content from '../components/pagestructure/content';
import NotFound from "../pagetemplates/notfound";

const PageContent = () => {
    const [content, setContent] = useState("");
    const [isHomePage, setIsHomePage] = useState(false);
    const [isHubPage, setIsHubPage] = useState(false);
    const [isProjectHubPage, setIsProjectHubPage] = useState(false);
    const [isContentPage, setIsContentPage] = useState(false);
    const [isNotFoundPage, setIsNotFoundPage] = useState(false)

    // ordinary content wraps and does not break sidebar
    // content in code blocks and other content that cannot wrap breaks sidebar if too wide

    useEffect(() => {
        const apiUrl = 'https://cms.davidbuckell.com' + window.location.pathname;
        (async () => {
            try {
                await axios.get(apiUrl).then((result) => {
                    setContent(result.data);
                    setIsHomePage(result.data.DocumentType === 'homePage');
                    setIsHubPage(result.data.DocumentType === 'hubPage');
                    setIsProjectHubPage(result.data.DocumentType === 'projectHubPage');
                    setIsContentPage(result.data.DocumentType === 'contentPage');
                    setIsNotFoundPage(false);
                });
            } catch (err) {
                setIsNotFoundPage(true);
                setIsHomePage(false);
                setIsHubPage(false);
                setIsProjectHubPage(false);
                setIsContentPage(false);
            }
        })();        

        if (document.body.classList.contains('is-preload')) {
            document.body.classList.remove('is-preload');
        }
    }, []);

    return (
        <React.Fragment>
            {isHomePage &&
                <React.Fragment>
                    <HomePageIntro pageContent={content} />
                    <PrimaryLinks pageContent={content} />
                </React.Fragment>
            }

            {(!isHomePage) &&
                <React.Fragment>
                    <Content pageContent={content} />
                </React.Fragment>
            }

            {(isHomePage || isHubPage) &&
                <React.Fragment>
                    <HubLinks pageContent={content} />
                </React.Fragment>
            }

            {(isProjectHubPage) &&
                <React.Fragment>
                    {/*<ProjectHubLinks pageContent={content} />*/}
                </React.Fragment>
            }

            {(isNotFoundPage) &&
                <React.Fragment>
                    <NotFound/>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default PageContent;