import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/nfl.css';

function Stats() {
    const [offensePassing, setOffensePassing] = useState(null);
    const [offenseRushing, setOffenseRushing] = useState(null);
    const [defensePassing, setDefensePassing] = useState(null);
    const [defenseRushing, setDefenseRushing] = useState(null);
    const [powerRankings, setPowerRankings] = useState(null);

    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/nflstatsdata';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            console.log(data.stats);

            // Offense Passing
            var offPass = Object.entries(data.stats).reduce((array, stats) => {
                return [...array, { teamNickname: stats[0], teamName: stats[1].teamName, yards: stats[1].offensePassingYards }].sort((teamA, teamB) => teamA.yards > teamB.yards ? -1 : 1)
            }, []);
            console.log(offPass);
            setOffensePassing(offPass);

            // Offense Rushing
            var offRush = Object.entries(data.stats).reduce((array, stats) => {
                return [...array, { teamNickname: stats[0], teamName: stats[1].teamName, yards: stats[1].offenseRushingYards }].sort((teamA, teamB) => teamA.yards > teamB.yards ? -1 : 1)
            }, []);
            setOffenseRushing(offRush);

            // Defense Passing
            var defPass = Object.entries(data.stats).reduce((array, stats) => {
                return [...array, { teamNickname: stats[0], teamName: stats[1].teamName, yards: stats[1].defensePassingYards }].sort((teamA, teamB) => teamA.yards > teamB.yards ? 1 : -1)
            }, []);
            setDefensePassing(defPass);

            // Defense Rushing
            var defRush = Object.entries(data.stats).reduce((array, stats) => {
                return [...array, { teamNickname: stats[0], teamName: stats[1].teamName, yards: stats[1].defenseRushingYards }].sort((teamA, teamB) => teamA.yards > teamB.yards ? 1 : -1)
            }, []);
            setDefenseRushing(defRush);

            // Power rankings
            var rankings = Object.entries(data.stats).reduce((array, stats) => {
                return [...array, { teamNickname: stats[0], teamName: stats[1].teamName, rank: stats[1].powerRanking }].sort((teamA, teamB) => teamA.rank > teamB.rank ? 1 : -1)
            }, []);
            setPowerRankings(rankings);
        });

    }, []);

    return (
        <React.Fragment>
            <PowerRankingsTable rankings={powerRankings} title="Power Rankings" />
            <div className="row">
                <StatTable stats={offensePassing} title="Offense Passing" />
                <StatTable stats={offenseRushing} title="Offense Rushing" />
                <StatTable stats={defensePassing} title="Defense Passing" />
                <StatTable stats={defenseRushing} title="Defense Rushing" />
            </div>
        </React.Fragment>
    );
}

const PowerRankingsTable = (props) => {
    var rankings = props.rankings;
    var title = props.title;
    return (
        <React.Fragment>
            <h5>{title}</h5>
            <div className="row">
                {rankings && rankings.map((team, index) => (
                    <div key={index}>
                        <span className="teamname">{team.rank} </span>
                        <img src={`/images/nfl/${team.teamName.replace(/\s/g, "").toLowerCase()}.svg`} alt={team.teamNickname} width="35em" />
                        <span className="teamname">{team.teamNickname}</span>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

const StatTable = (props) => {
    var stats = props.stats;
    var title = props.title;

    return (
        <div className="col-3 col-6-small">
            <div className="table-wrapper">
                <table className={`nfl`}>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>{title}</th>
                            <th>Yards</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats && stats.map((team, index) => (
                            <tr key={`${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                    <div className="teamImageRow">
                                        <img src={`/images/nfl/${team.teamName.replace(/\s/g, "").toLowerCase()}.svg`} alt={team.teamNickname} width="35em" />
                                        <span className="teamname">{team.teamNickname}</span>
                                    </div>
                                </td>
                                <td>{team.yards}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Stats;
