import React, { useEffect, useState } from "react";
import axios from 'axios';
import LineChart from "../../pagestructure/chart/linechart";
import Label from "../../pagestructure/chart/axislabel";
import ChartTitle from "../../pagestructure/chart/charttitle";

const staticData = [
  { label: "S", x: 0, y: 0 },
  { label: "M", x: 1, y: 400 },
  { label: "T", x: 2, y: 300 },
  { label: "W", x: 3, y: 100 },
  { label: "TH", x: 4, y: 400 },
  { label: "F", x: 5, y: 500 },
  { label: "S", x: 6, y: 400 }
];

const styles = {
  chartComponentsContainer: {
    display: 'grid', gridTemplateColumns: 'max-content 1000px', alignItems: 'center'
  },
  chartWrapper: { maxWidth: 1000, alignSelf: 'flex-start' }
}

const Parkrun = () => {
    const [parkruns, setParkruns] = useState([]);
    const [xMin, setXMin] = useState();
    const [xMax, setXMax] = useState();
    const [yMin, setYMin] = useState();
    const [yMax, setYMax] = useState();
    const [multiplier, setMultiplier] = useState(0);
    

    function showTablePoints() {
        setShowPoints(true);
    }

    function showTablePositions() {
        setShowPoints(false);
    }

    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/parkrundata';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            var runCount = data.Parkruns.length-1;
            var firstRun = data.Parkruns[0];            
            var lastRun = data.Parkruns[runCount];

            var elapsedTimes = data.Parkruns.map(a => a.ElapsedTime);
            var slowestRun = Math.max.apply(Math, elapsedTimes);
            var quickestRun = Math.min.apply(Math, elapsedTimes);

            console.log('times is ' + elapsedTimes);
            console.log('slowest is ' + slowestRun);
            console.log('quickest is ' + quickestRun);
            console.log(data.Parkruns[runCount].ActivityDate);
            setMultiplier(200 / 30);
            var mappedData = data.Parkruns.map((run, index) => 
              ({
                label: `${new Date(run.ActivityDate).getDate()}/${new Date(run.ActivityDate).getMonth()+1}`,
                x: index,
                y: Math.round(run.ElapsedTime)
              }));
            setParkruns(mappedData);
            setXMin(firstRun.ActivityDate);
            setXMax(lastRun.ActivityDate);
            console.log('set xmin to ' + firstRun.ActivityDate);            
        });
    }, []);

    return (
        <React.Fragment>
            <h3>Parkruns</h3><br />

          <div style={styles.chartComponentsContainer}>
            <div/>
            <ChartTitle text="Park runs"/>
            <Label text="Time" rotate/>
            <div style={styles.chartWrapper}>
              <LineChart
                width={500 }
                height={300}
                data={parkruns}
                horizontalGuides={15}
                precision={2}
                verticalGuides={1} />
            </div>
            <div/>
            <Label text="Date"/>
          </div>    

            <svg height={400} width="100%">
              <line x1={10} x2={200} y1={10} y2={200} stroke="black" strokeWidth="2" />              
              <line x1={100} y1={20} x2={120} y2={10} stroke="red" strokeWidth="2" />
              <line x1={120} y1={10} x2={140} y2={50} stroke="blue" strokeWidth="2" />
              <line x1={140} y1={50} x2={100} y2={20} stroke="green" strokeWidth="2" />
              <rect x={200} y={30} height={60} width={70} fill="none" style={{ strokeWidth: 5, stroke: "black"}} />
              <circle cx={50} cy={50} r={10} fill="red" />
              <path d="M 10 10 L 20 20 z" stroke="brown" strokeWidth="2" height={20} width={40} x={100} y={20} />
              <path d="M10 10"/>
              <path d="M 10 10 H 50 V 90 H 10 L 40 10" fill="transparent" stroke="purple"/>
              <path d="M 130 110 C 120 140, 180 140, 170 110" stroke="magenta" fill="transparent"/>
              <path d="M 10 80 C 40 10, 65 10, 95 80 S 150 150, 180 80" fill="transparent" stroke="pink" strokeWidth="5"/>
              <path d="M 10 315
                L 110 215
                A 30 50 0 0 1 162.55 162.45
                L 172.55 152.45
                A 30 50 -45 0 1 215.1 109.9
                L 315 10" stroke="black" fill="green" strokeWidth="2" fillOpacity="0.5"/>
              <path d="M 330 180
                A 45 45, 0, 1, 0, 275 125
                L 275 80 Z" fill="red"/>
              <path d="M 230 230
                A 45 45, 0, 1, 1, 275 275
                L 275 230 Z" fill="blue"/>

<rect x="10" y="10" width="30" height="30" stroke="black" fill="transparent" strokeWidth="5"/>
  <rect x="60" y="10" rx="10" ry="10" width="30" height="30" stroke="black" fill="transparent" strokeWidth="5"/>

  <circle cx="25" cy="75" r="20" stroke="red" fill="transparent" strokeWidth="5"/>
  <ellipse cx="75" cy="75" rx="20" ry="5" stroke="red" fill="transparent" strokeWidth="5"/>

  <line x1="10" x2="50" y1="110" y2="150" stroke="orange" strokeWidth="5"/>
  <polyline points="60 110 65 120 70 115 75 130 80 125 85 140 90 135 95 150 100 145"
      stroke="orange" fill="transparent" strokeWidth="5"/>

  <polygon points="50 160 55 180 70 180 60 190 65 205 50 195 35 205 40 190 30 180 45 180"
      stroke="green" fill="transparent" strokeWidth="5"/>

  <path d="M20,230 Q40,205 50,230 T90,230" fill="none" stroke="blue" strokeWidth="5"/>
            </svg>
        </React.Fragment>
    );
}

export default Parkrun;