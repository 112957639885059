import React from 'react';
import ReactMarkdown from "react-markdown";
import { Link } from 'react-router-dom';
import rehypeRaw from "rehype-raw";

function HomePageIntro(props) {
    var content = props.pageContent;

    return (
        <section id="banner">
            <div className="content">
                <header>
                    <h1>{content.Headline}</h1>
                    <p>{content.SubHeadline}</p>
                </header>                
                <ReactMarkdown children={content.Content} rehypePlugins={[rehypeRaw]}/>
                <ul className="actions">
                    <li><Link className='button big' to="/blogs/personal/about-me">{content.ReadMoreText}</Link></li>
                </ul>
            </div>
            <span className="image object">
                <img src={content.BannerImage} alt={content.ImageAlt} />
            </span>
        </section>
    );
}

export default HomePageIntro;