import React from "react";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { AuthenticatedTemplate } from "@azure/msal-react";

function NavigationLinks(props) {
    const navLinks = props.navLinks;
    const setNavLinks = props.setNavLinks;
    const selectedLinks = props.selectedLinks;
    const setSelectedLinks = props.setSelectedLinks;

    return (
        <nav id="menu">
            <header className="major">
                <h2>Navigation</h2>
            </header>
            <ul>
                {navLinks && (
                    <NavigationLink links={navLinks} setNavLinks={setNavLinks} selectedLinks={selectedLinks} setSelectedLinks={setSelectedLinks} />
                )}
                <AuthenticatedTemplate>
                <li>
                    <span className="opener">Secure Content</span>
                    <ul>
                        <li><Link to="secure-page">Secure Page</Link></li>
                    </ul>
                </li>
                </AuthenticatedTemplate>                
            </ul>
        </nav>
    );
}

const NavigationLink = (props) => {
    var links = props.links;
    var setNavLinks = props.setNavLinks;
    var selectedLinks = props.selectedLinks;
    var setSelectedLinks = props.setSelectedLinks;

    const toggleLink = link => {        
        /*
        when navs work the following logged:
        touch bailing
        in toggle link
        touchend bailing

        when nav does not work the following logged
        didnt touch bail
        */

        selectedLinks.includes(link.title.toLowerCase()) ? removeLink(link) : addLink(link);
    }

    const addLink = link => {
        setSelectedLinks([...selectedLinks, link.title.toLowerCase()]);
    }

    const removeLink = idx => {        
        const temp = [...selectedLinks];
        var index = temp.indexOf(idx.title.toLowerCase());
        if (index !== -1) {
            // remove element using splice
            temp.splice(index, 1);
            // updating list
            setSelectedLinks(temp);
        }        
    }

    return (
        <React.Fragment>
            { links && links.map((link, index) => (
                <React.Fragment key={index}>
                    {                        
                        link.childLinks.length ?
                            <li>
                                <span className={selectedLinks.includes(link.title.toLowerCase()) ? 'opener active' : 'opener'} onClick={() => toggleLink(link)}>{link.title}</span>
                                <ul>
                                    <NavigationLink links={link.childLinks} setNavLinks={setNavLinks} selectedLinks={selectedLinks} setSelectedLinks={setSelectedLinks} />
                                </ul>
                            </li>
                            : <li><NavLink to={link.url}>{link.title}</NavLink></li>
                    }
                </React.Fragment >
            ))
            }
        </React.Fragment >
    );
}

export default NavigationLinks;