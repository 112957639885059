import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccasAccordion from '../../pagestructure/accasaccordion';
import '../../../css/accumulators.css';

const Accumulators = () => {
    const [accas, setAccas] = useState(null);
    const [showAll, setShowAll] = useState(false);

    let ukPounds = Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
    });

    function toggleShowAll(e) {        
        e.preventDefault();
        setShowAll(!showAll);
    };

    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/accumulatorsdata?istopscorerbet=false';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            setAccas(data);
        });

    }, []);

    /*if (window.location.href.indexOf("#") > -1) {
        var betId = window.location.href.substring(window.location.href.indexOf("#") + 1);
        var img = $("a[name='" + betId + "']>div>div>div>img");
        $(img).click();
    }*/

    return (
        <React.Fragment>
            <article id="accumulators">
                <div className="showAll">
                    <a href="#" onClick={toggleShowAll}>Show/Hide All</a>
                </div>
                <div className="key">
                    <strong>Key:</strong>
                    <span>&nbsp;&nbsp;<img src="/images/betting/losing.png" width="1.5%" alt="losing" /> Losing</span>
                    <span>&nbsp;&nbsp;<img src="/images/betting/winning.png" width="1.5%" alt="winning" /> Winning</span>
                    <span>&nbsp;&nbsp;<img src="/images/betting/won.png" width="1.5%" alt="won" /> Won</span>
                    <span>&nbsp;&nbsp;<img src="/images/betting/pending.png" width="1.5%" alt="pending" /> Pending</span>
                    <br />
                </div>
                <div className="header col-xs-12">
                    <div className="col-xs-1"></div>
                    <div className="col-xs-10 text-center">
                        {accas ? (
                            <React.Fragment>
                                <span>{accas.betCount} active bets staking </span>
                                {ukPounds.format(accas.totalStake)}
                                <span> &amp; winning </span>                                
                                {ukPounds.format(accas.currentPayout)}
                                <span> of </span>
                                {ukPounds.format(accas.maxPayout)}
                            </React.Fragment>
                        ) : ("Loading Accumulators...")}
                    </div>
                    <div className="col-xs-1"></div>
                </div>

                {accas && accas.bets && accas.bets.map((bet, index) => (
                    <AccasAccordion
                        key={`${bet.ShortBetId}-${index}`}
                        bet={bet}
                        displayAll={showAll}
                    />
                ))}

                {accas && accas.incorrectSelections && accas.incorrectSelections.length > 0 &&
                    <React.Fragment>
                        <hr />
                        <h4>Incorrect Selections</h4>
                        <div className="table-wrapper">
                            <table className="incorrectSelections">
                                <thead>
                                    <tr>
                                        <th>Count</th>
                                        <th>Selection</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accas.incorrectSelections.map((selection, index) => (
                                        <tr key={index}>
                                            <td className="cnt">{selection.value}</td>
                                            <td className="sel">{selection.key}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                }
            </article>
        </React.Fragment>
    );
}

export default Accumulators;