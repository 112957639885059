import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../css/nfl.css';

function Standings() {    
    const [divisions, setDivisions] = useState(null);
    useEffect(() => {
        const apiUrl = 'https://api.davidbuckell.com/api/nflstandingsdata';
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            setDivisions(data.divisions);
        });

    }, []);

    return (
        <div className="row">                
            {divisions && Object.entries(divisions).map(([division, teams]) => (
                <div key={division} className="col-6 col-12-small">
                    <div className="table-wrapper">                     
                        <table className={`nfl ${division.substring(0,3).toLowerCase()}`}>
                            <thead>
                                <tr>
                                    <th>{division}</th>
                                    <th>Won</th>
                                    <th>Lost</th>
                                    <th>Tied</th>
                                    <th>Win%</th>
                                    <th>PF</th>
                                    <th>PA</th>
                                    <th>Net Pts</th>
                                    <th>Home</th>
                                    <th>Road</th>
                                </tr>
                            </thead>
                            <tbody>
                            {teams && teams.map((team, index) => (
                                <tr key={`${division}-${index}`}>                                            
                                    <td>
                                        <div className="teamImageRow">
                                            <img src={`/images/nfl/${team.team.replace(/\s/g, "").toLowerCase()}.svg`} alt={team.team} width="35em" />
                                            <span className="teamname">{team.team}</span>
                                        </div>
                                    </td>
                                    <td>{team.won}</td>
                                    <td>{team.lost}</td>
                                    <td>{team.tied}</td>
                                    <td>{team.winningPercentage}%</td>
                                    <td>{team.pointsFor}</td>
                                    <td>{team.pointsAgainst}</td>
                                    <td>{team.netPoints}</td>
                                    <td>{team.homeRecord}</td>
                                    <td>{team.awayRecord}</td>
                                </tr>                                                
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Standings;