import React from 'react';
import EnglishLeagueResults from '../components/projects/football/englishLeagueResults';

function FootballResults() {
    return (
        <div className="page">
            <EnglishLeagueResults />
        </div>
    );
}

export default FootballResults;