import React, { useEffect, useState } from "react";
import axios from 'axios';

const SavingsAdmin = () => {
    const [secret, setSecret] = useState("");
    const [startDate, setStartDate] = useState(new Date().toJSON());
    const [interestRate, setInterestRate] = useState(0);
    const [savingsAmount, setSavingsAmount] = useState(0);
    const apiUrl = 'https://api.davidbuckell.com/api/savingsinterestsettingsdata';


    useEffect(() => {
        axios.get(apiUrl).then((result) => {
            var data = result.data;
            var mappedData = data.settings.map((row, index) =>
            ({
                startDate: `${new Date(row.startDate).toLocaleDateString()}`,
                endDate: `${new Date(row.endDate).toLocaleDateString()}`,
                interestRate: row.interestRate,
                savingsAmount: row.savingsAmount
            }));
            var lastRow = mappedData[mappedData.length - 1];
            setInterestRate(lastRow.interestRate);
            setSavingsAmount(lastRow.savingsAmount);
        });
    }, []);

    function updateStartDate(e) {
        var selectedDate = new Date(e.target.value).toJSON();
        setStartDate(selectedDate);
    }

    function updateInterestRate(e) {
        var newRate = e.target.value;
        setInterestRate(newRate);
    }

    function updateSavingsAmount(e) {
        var newAmount = e.target.value;
        setSavingsAmount(newAmount);
    }

    function updateSecret(e) {        
        setSecret(e.target.value);
    }

    function submitSettings(e) {
        e.preventDefault();

        const params = new URLSearchParams();
        params.append('secret', secret);
        params.append('interestRate', parseFloat(interestRate));
        params.append('savingsAmount', parseInt(savingsAmount));
        params.append('dateChanged', startDate);

        axios.post(apiUrl, params);
    }

    return (
        <React.Fragment>
            <h4>Update Settings</h4>
            <form className="savingsAdminForm" onSubmit={submitSettings}>
                <div className="row gtr-uniform">
                    <div className="col-5">
                        <input type="date" name="startdate" placeholder={startDate} onChange={(e) => updateStartDate(e)} />
                    </div>
                    <div className="col-3">
                        <input type="text" name="interestRate" placeholder={interestRate} onChange={(e) => updateInterestRate(e)} />
                    </div>
                    <div className="col-4">
                        <input type="text" name="savingsAmount" placeholder={savingsAmount} onChange={(e) => updateSavingsAmount(e)} />
                    </div>                    
                </div>
                <div className="row"><br/></div>
                <div className="row">
                    <div className="col-6-xsmall">
                        <input type="text" name="secret" onChange={(e) => updateSecret(e)}/>
                    </div>
                    <div className="col-6-xsmall">
                        <ul className="actions">
                            <li>
                                <input type="submit" value="Submit" className="primary" />
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
}

export default SavingsAdmin;